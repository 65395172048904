import {
  AsyncThunk,
  AsyncThunkOptions,
  AsyncThunkPayloadCreator,
  createAsyncThunk as reduxCreateAsyncThunk,
} from '@reduxjs/toolkit';

import { HttpClient } from '@wix/yoshi-flow-editor';
import { IUser } from '@wix/native-components-infra/dist/src/types/types';

import type { AsyncThunkConfig, I18NextTransProps } from './types';

type ThunkOptions<Arg> = AsyncThunkOptions<Arg, AsyncThunkConfig> & {
  hideErrorMessage?: boolean | ((error: unknown) => boolean);

  formatError?(arg: Arg): I18NextTransProps;
};

type ReduxAsyncThunkConfig = {
  pendingMeta?: unknown;
  serializedErrorType?: unknown;
};

export function createAsyncThunk<
  Returned,
  Arg = void,
  Config extends ReduxAsyncThunkConfig = {},
>(
  prefix: string,
  payloadCreator: AsyncThunkPayloadCreator<Returned, Arg, AsyncThunkConfig>,
  options: ThunkOptions<Arg> = {},
) {
  const create = reduxCreateAsyncThunk.withTypes<AsyncThunkConfig>();

  return create(
    prefix,
    async function (arg, thunkApi) {
      const { dispatch, extra: flowApi } = thunkApi;

      const {
        hideErrorMessage = false,
        formatError = defaultErrorFormatter(prefix),
      } = options;

      try {
        const result = await payloadCreator(arg, thunkApi);

        return result;
      } catch (error) {
        const hideToast =
          typeof hideErrorMessage === 'function'
            ? hideErrorMessage(error)
            : hideErrorMessage;

        if (!hideToast) {
          dispatch({
            type: 'application/showToast',
            payload: {
              type: 'error',
              action: prefix,
              i18nParams: formatError(arg),
              requestId: HttpClient.isHttpError(error)
                ? error.requestId
                : undefined,
              description: HttpClient.isHttpError(error)
                ? error.message
                : undefined,
            },
          });
        }

        flowApi.errorMonitor.captureException(error as Error);

        if (HttpClient.isHttpError(error)) {
          console.error(prefix, error.requestId, error.response);
        }

        console.error(prefix, error);

        throw error;
      }
    },
    options,
  ) as AsyncThunk<Returned, Arg, Config & AsyncThunkConfig>;
}

export function defaultErrorFormatter(name: string) {
  const key = name.replace(/:/g, '.');

  return () => ({
    i18nKey: `groups-web.toast.error.${key}`,
  });
}

export function is404(error: unknown) {
  if (!HttpClient.isHttpError(error)) {
    return false;
  }

  return error?.response?.status === 404;
}

function parseJWT(jwt?: string) {
  if (!jwt) {
    throw new Error('parseJWT::Missing instance');
  }

  const atob =
    typeof self !== 'undefined' && self.atob
      ? self.atob.bind(self)
      : // Accessing 'Buffer' via 'global' prevents webpack polyfill it for web environment
        (str: string) =>
          (global as any).Buffer.from(str, 'base64').toString('utf8');

  const [, base64data] = jwt.split('.', 2);

  return JSON.parse(atob(base64data));
}

export function getCurrentUserWithFallbackToInstance(
  currentUser: IUser,
  instance?: string,
) {
  const parsedInstance = parseJWT(instance);

  return {
    ...currentUser,
    instance,
    id: currentUser.id || parsedInstance.siteMemberId,
    // Workaround: user.loggedIn is false when rendered OOI in Editor
    loggedIn: currentUser.loggedIn || Boolean(parsedInstance.siteMemberId),
  };
}

export function serializeHttpError(error: unknown) {
  if (!HttpClient.isHttpError(error)) {
    return error;
  }

  return error.response?.data;
}
